<template>
    <section class="cart-section section-b-space">
        <div class="container">
            <div class="row">
                <div class="col-sm-4">
                    <div class="dashboard-right">
                        <div class="dashboard">
                            <div class="page-title">
                                <h3>Informations Personnelles</h3>
                                <div class="box">
                                    <table class="table">
                                        <tr>
                                            <td>Nom</td>
                                            <td>{{ customer.customer.lname }}</td>
                                        </tr>
                                        <tr>
                                            <td>Prénom</td>
                                            <td>{{ customer.customer.fname }}</td>
                                        </tr>
                                        <tr>
                                            <td>CIM</td>
                                            <td>{{ customer.customer.code }}</td>
                                        </tr>
                                        <tr>
                                            <td>STATUT (NIVEAU)</td>
                                            <td>{{ customer.customer.level.name }}</td>
                                        </tr>
                                        <tr>
                                            <td>SITUATION</td>
                                            <td><span class="badge" style="color: white"
                                                      :style="{backgroundColor: customer.customer.situationColor}">{{ customer.customer.situationLabel }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>REFERENCE</td>
                                            <td>{{ customer.customer.reference ? customer.customer.reference.fname+" "+
                                                customer.customer.reference.lname : ""}}<br/>
                                                {{ customer.customer.reference ? customer.customer.reference.code : ""
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-right mt-2">
                        <div class="dashboard">
                            <div class="box">
                                <p>Vous avez
                                    <countdown :time="timing">
                                        <template slot-scope="props">
                                            <strong style="font-size: 1.5em">
                                                {{ props.days }} jour(s)
                                            </strong>
                                            {{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
                                        </template>
                                    </countdown>
                                    d’accès à votre bureau
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-right mt-2">
                        <div class="dashboard">
                            <div class="page-title">
                                <h5>Partager le lien de Parrainnage</h5>
                                <div class="box">
                                    <input
                                            type="text"
                                            class="form-control"
                                            :value="customer.customer.sharedLink"
                                    />
                                    <!--<button class="btn btn-outline-secondary rounded m-1"-->
                                            <!--@click="copyLink(customer.customer.sharedLink)"-->
                                            <!--data-toggle="tooltip" data-placement="bottom" title="Copier le lien"-->
                                    <!--&gt;-->
                                        <!--<i class="fa fa-copy"></i>-->
                                    <!--</button>-->
                                    <ShareNetwork
                                            v-for="(lks, i) in shareLinks"
                                            :key="i"
                                            :network="lks.network"
                                            :url=customer.customer.sharedLink
                                            title="ASCENSION GROUP"
                                            description="Cliquer sur le lien ci-dessus pour vous inscrire sur Ascension Group."
                                    >
                                        <button class="btn btn-primary rounded m-1"
                                                data-toggle="tooltip" data-placement="bottom" :title="lks.title"
                                        >
                                            <i :class="lks.icon"></i>
                                        </button>
                                    </ShareNetwork>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="dashboard-right">
                        <div class="dashboard">
                            <div class="page-title">
                                <h3>Statistique de votre reseau</h3>
                                <div class="box">

                                    <div class="counter-section">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="counter-box">
                                                    <img :src='"@/assets/images/icon/family-tree-01.png"'
                                                         class="img-fluid">
                                                    <div>
                                                        <h3>{{ customer.descendantCount }}</h3>
                                                        <h5>Effectifs total</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="counter-box">
                                                    <img :src='"@/assets/images/icon/family-tree-02.png"'
                                                         class="img-fluid">
                                                    <div>
                                                        <h3>{{ customer.childrenCount }}</h3>
                                                        <h5>Inscription(s) directe(s)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="counter-box">
                                                    <img :src='"@/assets/images/icon/family-tree-03.png"'
                                                         class="img-fluid">
                                                    <div>
                                                        <h3>{{ customer.descendantCount-customer.childrenCount
                                                            }}</h3>
                                                        <h5>Inscription(s) indirecte(s)</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="dashboard mt-3">
                            <div class="page-title">
                                <h5>Structure de votre reseau</h5>
                                <div class="box">

                                    <div class="counter-section">
                                        <div class="row">
                                            <div class="col-sm-6" v-for="(item, i) in networking" :key="i">
                                                <h3>{{ item.lvel }}</h3>
                                                <h5>{{ item.qty }}</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="dashboard-right">
                        <div class="dashboard mt-3">
                            <div class="page-title">
                                <h3>Cycle</h3>
                                <div class="box">
                                    <p>Votre cycle ({{ customer.customer.cycleRange }}) prend fin dans
                                        <countdown :time="cycle">
                                            <template slot-scope="props">
                                                <strong style="font-size: 1.5em">
                                                    {{ props.days }} jour(s)
                                                </strong>
                                                {{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
                                            </template>
                                        </countdown>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </section>
</template>

<script>
  import { mapGetters } from "vuex";
  import VueCountdown from "@chenfengyuan/vue-countdown";
  import _ from "lodash";
  import axios from "axios";


  export default {
    name: "index",

    components: {
      "countdown": VueCountdown
    },

    data() {
      return {
        customer: "",

        shareLinks: [
          {
            network: "email",
            color: "",
            icon: "fa fa-envelope",
            title: "Partager via Mail"
          },
          {
            network: "linkedin",
            color: "",
            icon: "fa fa-linkedin",
            title: "Partager sur LinkedIN"
          },
          {
            network: "facebook",
            color: "",
            icon: "fa fa-facebook",
            title: "Partager sur Facebook"
          },
          // {
          //   network: "messenger",
          //   color: '',
          //   icon: "fab fa-facebook-messenger",
          //   title: 'Partager sur Messenger'
          // },
          {
            network: "twitter",
            color: "",
            icon: "fa fa-twitter",
            title: "Partager sur Twitter"
          },
          {
            network: "whatsapp",
            color: "",
            icon: "fa fa-whatsapp",
            title: "Partager sur Whatsapp"
          },
          {
            network: "telegram",
            color: "",
            icon: "fa fa-telegram",
            title: "Partager sur Telegram"
          }
        ]
      };
    },

    watch: {
      user: {
        immediate: true,
        handler: function(val) {
          this.fetchCustomerNetwork({ customer: val.id, dashtab: "dashboard" });
        }
      }
    },

    methods: {
      async fetchCustomerNetwork(payload) {
        let loader = this.$loading.show();
        let pdts = await axios.get(`customers/${payload.customer}/${payload.dashtab}`);
        this.customer = pdts.data.data;
        loader.hide();
      },

      copyLink(url) {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            alert("Copier au Clipboard");
          })
          .catch(() => {
            alert("Oops... quelque chose ne va pas. Veuillez utliser les autres liens");
          });
      }
    },

    computed: {
      ...mapGetters({
        user: "auth/user"
      }),


      timing() {
        return this.customer.customer.access_time;
      },

      cycle() {
        return this.customer.customer.cycle;
      },

      networking() {
        console.log("item", this.customer.children);
        return _.map(_.keys(this.customer.structure), item => ({
          lvel: item,
          qty: this.customer.structure[item]
        }));
      }
    },

    mounted() {
      this.fetchCustomerNetwork({ customer: this.user.id, dashtab: "dashboard" });
    }
  };
</script>

<style scoped>

</style>
